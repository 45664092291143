/**
 * 微信表情库
 */
 export function emotions() {
  let arr = []
  let textarr = Object.keys(getEmoTionList())
  for (let i = 0; i < 105; i++) {
    let obj = {
      text: textarr[i],
      // img:`http://qzonestyle.gtimg.cn/qzone/em/e${i}.gif`
      img: `https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${i}.gif`

    }
    arr.push(obj)

  }
  return arr
}
export function getEmoTionList() {
  return {
    "微笑": {
      "cn": "微笑",
      "zb": "Smile",
      "emoji": "🙂"
    },
    "撇嘴": {
      "cn": "撇嘴",
      "zb": "Grimace",
      "emoji": "😦"
    },
    "色": {
      "cn": "色",
      "zb": "Drool",
      "emoji": "😍"
    },
    "发呆": {
      "cn": "发呆",
      "zb": "Scowl",
      "emoji": "😍"
    },
    "得意": {
      "cn": "得意",
      "zb": "CoolGuy",
      "emoji": "😎"
    },
    "流泪": {
      "cn": "流泪",
      "zb": "Sob",
      "emoji": "😭"
    },
    "害羞": {
      "cn": "害羞",
      "zb": "Shy",
      "emoji": "😊"
    },
    "闭嘴": {
      "cn": "闭嘴",
      "zb": "Silent",
      "emoji": "😷"
    },
    "睡": {
      "cn": "睡",
      "zb": "Sleep",
      "emoji": "😴"
    },
    "大哭": {
      "cn": "大哭 ",
      "zb": "Cry ",
      "emoji": "😡"
    },
    "尴尬": {
      "cn": "尴尬",
      "zb": "Awkward",
      "emoji": "😡"
    },
    "发怒": {
      "cn": "发怒",
      "zb": "Angry",
      "emoji": "😛"
    },
    "调皮": {
      "cn": "调皮",
      "zb": "Tongue",
      "emoji": "😀"
    },
    "呲牙": {
      "cn": "呲牙",
      "zb": "Grin",
      "emoji": "😯"
    },
    "惊讶": {
      "cn": "惊讶",
      "zb": "Surprise",
      "emoji": "🙁"
    },
    "难过": {
      "cn": "难过",
      "zb": "Frown",
      "emoji": "😎"
    },
    "酷": {
      "cn": "酷",
      "zb": "Ruthless",
      "emoji": "😨"
    },
    "冷汗": {
      "cn": "冷汗",
      "zb": "Blush",
      "emoji": "😱"
    },
    "抓狂": {
      "cn": "抓狂",
      "zb": "Scream",
      "emoji": "😵"
    },
    "吐 ": {
      "cn": "吐 ",
      "zb": "Puke ",
      "emoji": "😋"
    },
    "偷笑": {
      "cn": "偷笑",
      "zb": "Chuckle",
      "emoji": "☺"
    },
    "愉快": {
      "cn": "愉快",
      "zb": "Joyful",
      "emoji": "🙄"
    },
    "白眼": {
      "cn": "白眼",
      "zb": "Slight",
      "emoji": "🙄"
    },
    "傲慢": {
      "cn": "傲慢",
      "zb": "Smug",
      "emoji": "😋"
    },
    "饥饿": {
      "cn": "饥饿",
      "zb": "Hungry",
      "emoji": "😪"
    },
    "困": {
      "cn": "困",
      "zb": "Drowsy",
      "emoji": "😫"
    },
    "惊恐": {
      "cn": "惊恐",
      "zb": "Panic",
      "emoji": "😓"
    },
    "流汗": {
      "cn": "流汗",
      "zb": "Sweat",
      "emoji": "😃"
    },
    "憨笑": {
      "cn": "憨笑",
      "zb": "Laugh",
      "emoji": "😃"
    },
    "悠闲 ": {
      "cn": "悠闲 ",
      "zb": "Commando ",
      "emoji": "😆"
    },
    "奋斗": {
      "cn": "奋斗",
      "zb": "Determined",
      "emoji": "😆"
    },
    "咒骂": {
      "cn": "咒骂",
      "zb": "Scold",
      "emoji": "😆"
    },
    "疑问": {
      "cn": "疑问",
      "zb": "Shocked",
      "emoji": "😆"
    },
    "嘘": {
      "cn": "嘘",
      "zb": "Shhh",
      "emoji": "😵"
    },
    "晕": {
      "cn": "晕",
      "zb": "Dizzy",
      "emoji": "😆"
    },
    "疯了": {
      "cn": "疯了",
      "zb": "Tormented",
      "emoji": "😆"
    },
    "衰": {
      "cn": "衰",
      "zb": "Toasted",
      "emoji": "😆"
    },
    "骷髅": {
      "cn": "骷髅",
      "zb": "Skull",
      "emoji": "💀"
    },
    "敲打": {
      "cn": "敲打",
      "zb": "Hammer",
      "emoji": "😬"
    },
    "再见 ": {
      "cn": "再见 ",
      "zb": "Wave ",
      "emoji": "😘"
    },
    "擦汗": {
      "cn": "擦汗",
      "zb": "Speechless",
      "emoji": "😆"
    },
    "抠鼻": {
      "cn": "抠鼻",
      "zb": "NosePick",
      "emoji": "😆"
    },
    "鼓掌": {
      "cn": "鼓掌",
      "zb": "Clap",
      "emoji": "👏"
    },
    "糗大了": {
      "cn": "糗大了",
      "zb": "Shame",
      "emoji": "😆"
    },
    "坏笑": {
      "cn": "坏笑",
      "zb": "Trick",
      "emoji": "😆"
    },
    "左哼哼": {
      "cn": "左哼哼",
      "zb": "Bah！L",
      "emoji": "😆"
    },
    "右哼哼": {
      "cn": "右哼哼",
      "zb": "Bah！R",
      "emoji": "😆"
    },
    "哈欠": {
      "cn": "哈欠",
      "zb": "Yawn",
      "emoji": "😆"
    },
    "鄙视": {
      "cn": "鄙视",
      "zb": "Pooh-pooh",
      "emoji": "😆"
    },
    "委屈 ": {
      "cn": "委屈 ",
      "zb": "Shrunken ",
      "emoji": "😆"
    },
    "快哭了": {
      "cn": "快哭了",
      "zb": "TearingUp",
      "emoji": "😆"
    },
    "阴险": {
      "cn": "阴险",
      "zb": "Sly",
      "emoji": "😆"
    },
    "亲亲": {
      "cn": "亲亲",
      "zb": "Kiss",
      "emoji": "😘"
    },
    "吓": {
      "cn": "吓",
      "zb": "Wrath",
      "emoji": "😓"
    },
    "可怜": {
      "cn": "可怜",
      "zb": "Whimper",
      "emoji": "😆"
    },
    "菜刀": {
      "cn": "菜刀",
      "zb": "Cleaver",
      "emoji": "🔪"
    },
    "西瓜": {
      "cn": "西瓜",
      "zb": "Watermelon",
      "emoji": "🍉"
    },
    "啤酒": {
      "cn": "啤酒",
      "zb": "Beer",
      "emoji": "🍺"
    },
    "篮球": {
      "cn": "篮球",
      "zb": "Basketball",
      "emoji": "🏀"
    },
    "乒乓 ": {
      "cn": "乒乓 ",
      "zb": "PingPong ",
      "emoji": "⚪"
    },
    "咖啡": {
      "cn": "咖啡",
      "zb": "Coffee",
      "emoji": "☕"
    },
    "饭": {
      "cn": "饭",
      "zb": "Rice",
      "emoji": "🍚"
    },
    "猪头": {
      "cn": "猪头",
      "zb": "Pig",
      "emoji": "🐷"
    },
    "玫瑰": {
      "cn": "玫瑰",
      "zb": "Rose",
      "emoji": "🌹"
    },
    "凋谢": {
      "cn": "凋谢",
      "zb": "Wilt",
      "emoji": "🌹"
    },
    "嘴唇": {
      "cn": "嘴唇",
      "zb": "Lips",
      "emoji": "👄"
    },
    "爱心": {
      "cn": "爱心",
      "zb": "Heart",
      "emoji": "💗"
    },
    "心碎": {
      "cn": "心碎",
      "zb": "BrokenHeart",
      "emoji": "💔"
    },
    "蛋糕": {
      "cn": "蛋糕",
      "zb": "Cake",
      "emoji": "🎂"
    },
    "闪电 ": {
      "cn": "闪电 ",
      "zb": "Lightning ",
      "emoji": "⚡"
    },
    "炸弹": {
      "cn": "炸弹",
      "zb": "Bomb",
      "emoji": "💣"
    },
    "刀": {
      "cn": "刀",
      "zb": "Dagger",
      "emoji": "🗡"
    },
    "足球": {
      "cn": "足球",
      "zb": "Soccer",
      "emoji": "⚽"
    },
    "瓢虫": {
      "cn": "瓢虫",
      "zb": "Ladybug",
      "emoji": "🐞"
    },
    "便便": {
      "cn": "便便",
      "zb": "Poop",
      "emoji": "💩"
    },
    "月亮": {
      "cn": "月亮",
      "zb": "Moon",
      "emoji": "🌙"
    },
    "太阳": {
      "cn": "太阳",
      "zb": "Sun",
      "emoji": "☀"
    },
    "礼物": {
      "cn": "礼物",
      "zb": "Gift",
      "emoji": "🎁"
    },
    "拥抱": {
      "cn": "拥抱",
      "zb": "Hug",
      "emoji": "🤗"
    },
    "强 ": {
      "cn": "强 ",
      "zb": "ThumbsUp ",
      "emoji": "👍"
    },
    "弱": {
      "cn": "弱",
      "zb": "ThumbsDown",
      "emoji": "👎"
    },
    "握手": {
      "cn": "握手",
      "zb": "Shake",
      "emoji": "👍"
    },
    "胜利": {
      "cn": "胜利",
      "zb": "Peace",
      "emoji": "✌"
    },
    "抱拳": {
      "cn": "抱拳",
      "zb": "Fight",
      "emoji": "✊"
    },
    "勾引": {
      "cn": "勾引",
      "zb": "Beckon",
      "emoji": "✌"
    },
    "拳头": {
      "cn": "拳头",
      "zb": "Fist",
      "emoji": "✊"
    },
    "差劲": {
      "cn": "差劲",
      "zb": "Pinky",
      "emoji": "✌"
    },
    "爱你": {
      "cn": "爱你",
      "zb": "RockOn",
      "emoji": "✌"
    },
    "NO": {
      "cn": "NO",
      "zb": "Nuh-uh",
      "emoji": "✌"
    },
    "OK": {
      "cn": "OK",
      "zb": "OK",
      "emoji": "🙂"
    },
    "嘿哈": {
      "cn": "嘿哈",
      "zb": "Hey",
      "emoji": "🙂"
    },
    "捂脸": {
      "cn": "捂脸",
      "zb": "Facepalm",
      "emoji": "🙂"
    },
    "奸笑": {
      "cn": "奸笑",
      "zb": "Smirk",
      "emoji": "🙂"
    },
    "机智": {
      "cn": "机智",
      "zb": "Smart",
      "emoji": "🙂"
    },
    "皱眉": {
      "cn": "皱眉",
      "zb": "Concerned",
      "emoji": "🙂"
    },
    "耶": {
      "cn": "耶",
      "zb": "Yeah!",
      "emoji": "🙂"
    },
    "吃瓜": {
      "cn": "吃瓜",
      "zb": "Onlooker",
      "emoji": "🙂"
    },
    "加油": {
      "cn": "加油",
      "zb": "GoForIt",
      "emoji": "🙂"
    },
    "汗": {
      "cn": "汗",
      "zb": "Sweats",
      "emoji": "🙂"
    },
    "天啊": {
      "cn": "天啊",
      "zb": "OMG",
      "emoji": "👌"
    },
    "社会社会": {
      "cn": "社会社会",
      "zb": "Respect",
      "emoji": "🙂"
    },
    "旺柴": {
      "cn": "旺柴",
      "zb": "Doge",
      "emoji": "🙂"
    },
    "好的": {
      "cn": "好的",
      "zb": "NoProb",
      "emoji": "🙂"
    },
    "哇": {
      "cn": "哇",
      "zb": "Wow",
      "emoji": "🙂"
    },
    "囧": {
      "cn": "囧 ",
      
    },


  }
}