<template>
  <div class="container">
    <div class="wrap">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="60px"
        class="ruleForm"
      >
        <el-form-item label="标题" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写名称"
            maxlength="24"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input
            v-model="ruleForm.desc"
            placeholder="请填写描述"
            maxlength="24"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="url">
          <el-upload
            class="uploader"
            action="no"
            :show-file-list="false"
            :on-change="imgSaveToUrl"
            :auto-upload="false"
            accept="image/jpeg,image/jpg,image/png,image/gif"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.url" :src="ruleForm.url" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="ruleForm.link" placeholder="请填写链接"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="line"></div>

    <div class="wrap">
      <p class="preview">预览效果</p>
      <div class="preview-cell">
        <div class="cell-left">
          <p>{{ ruleForm.name }}</p>
          <p class="cell-desc">{{ ruleForm.desc }}</p>
        </div>
        <img :src="ruleForm.url" />
      </div>

      <div class="foot-wrap">
        <el-button type="primary" @click="submitAction('ruleForm')">确 定</el-button>
        <el-button @click="cancelAction">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { CreateLinkMaterial, UpLoadImageOrVideo } from "@/helper/material";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "CreateLink",
  setup(props, context) {
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      name: "",
      desc: "",
      url:'',
      link:""
    });

    const rules = reactive({
      name: [
        { required: true, message: "请填写名称", trigger: "blur" },
        { min: 1, max: 24, message: "长度在 1 到 24 个字符", trigger: "blur" },
      ],
      desc: [{ required: true, message: "请填写描述", trigger: "blur" }],
      url: [{ required: true, message: "请选择图片", trigger: "change" }],
      link: [{ required: true, message: "请选择链接地址", trigger: "blur" }],
    });

    //重置
    function resetAction(){
        ruleForm.name= "";
        ruleForm.desc= "";
        ruleForm.url= "";
        ruleForm.link= "";
    }

    /***********************自定义事件**************************/
     //检测图片
    function beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      const isImage= file.type == 'image/png' || 'image/jpg'|| 'image/jpeg' || 'image/gif';
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4MB!",
        });
      }
      if (!isImage) {
        ElMessage({
            type: "error",
            message: "上传图片只支持png/jpeg/jpg/gif!",
        });
      }
      return isLt2M&&isImage;
    }

    //非自动上传
    function imgSaveToUrl(event) {
      const formData = new FormData();
      formData.append("file", event.raw);
      UpLoadImageOrVideo(2002,formData).then((res) => {
        ruleForm.url = res.OSSUrl;
        ElMessage.success({
          message: "上传成功",
          type: "success",
        });
      });
    }

    //取消行为
    function cancelAction() {
      context.emit("cancel");
    }

    //提交
    async function submitAction() {
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        //接口提交成功
        let obj = {
          isSelect: false,
          Type: 2005,
          MsgContent: ruleForm.url,
          MsgHref: ruleForm.link,
          MsgTitle: ruleForm.name,
          MsgDesc: ruleForm.desc,
        };
        CreateLinkMaterial(obj).then((res) => {
          context.emit("submit", obj);
        });
      } catch (error) {
        console.log(error);
      }
    }
    return {
      ruleFormRef,
      ruleForm,
      rules,
      imgSaveToUrl,
      submitAction,
      cancelAction,
      resetAction,
      beforeAvatarUpload
    };
  },
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .wrap {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .uploader {
      width: 40px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-sizing: border-box;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .line {
    height: 100%;
    width: 2px;
    background-color: #ccc;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .foot-wrap {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.preview-cell {
  position: relative;
  margin-left: 15px;
  width: 240px;
  height: 100px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #ccc;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  img {
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }

  .cell-left {
    height: 80px;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    color: #000;
    padding: 5px;
    box-sizing: border-box;

    .cell-desc {
      font-size: 12px;
      color: #ccc;
    }
    p {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
  }
}
</style>